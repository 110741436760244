import axios from 'axios';
import { AuthFormData, AuthLoginData } from '../types/AuthTypes';
import authMap from '../models/AuthMap';
import getEnvs from '../../../../env/env';

const { api } = getEnvs();

const registerUser = (formData: AuthFormData): Promise<any> => {
  return axios.post(`${api}auth/register`, formData);
};

const loginUser = (loginData: AuthLoginData): Promise<any> => {
  return axios.post(`${api}auth/login`, loginData);
};

const logOut = () => {
  Object.keys(authMap).map((key) => localStorage.removeItem(key));
};

export { registerUser, loginUser, logOut };
