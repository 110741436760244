import styled from '@emotion/styled';
import { getColor, getSpace } from '../../../../../shared';
import ReactSelect, { OptionProps } from 'react-select';
import { SelectProps, StateManagedSelect } from './types';
import { SelectOption } from '../../types';
import { getDefaultTextProps } from '../../../../Text/shared.styles';
import { GridContainer } from '../../../../Containers';
import { motion } from 'framer-motion';

export const TitleContainer = styled.div`
  ${({ color }) => color && `color: ${color};`}
`;

export const DropdownIndicatorIcon = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid ${getColor('main-deep-4')};
  margin: 14px 10px 14px 0;
`;

export const ClearIndicator = styled.div`
  width: 10px;
  height: 10px;
  background: red;
`;

export const MultiValuesContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  background-color: ${getColor('main-deep-1')};
  border-radius: 4px;
  padding: ${getSpace('2')} ${getSpace('8')};
  margin: 2px;

  & > span {
    line-height: 18px;
  }

  & > div {
    &:hover {
      background: ${getColor('main-deep-2')};
    }
  }
`;

export const DeleteIconContainer = styled.div`
  margin-left: 5px;
  min-height: 18px;

  & svg {
    color: ${getColor('main-deep-5')};
  }
`;

export const SelectValuesContainer = styled.div`
  & > div > div {
    padding: ${getSpace('2')} !important;
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
`;

export const ControlContainer = styled.div<
  Required<Pick<SelectProps<boolean>, 'error' | 'isDisabled'>>
>`
  & .select__control {
    border: 1px solid ${getColor('main-deep-4')};
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    min-height: 32.2px;
    box-shadow: none;

    &:hover {
      border: 1px solid ${getColor('main-deep-4')};
      box-shadow: 0 0 5px 1px ${getColor('main-deep-2')};
    }

    & > div {
      padding: ${getSpace('2')} !important;
    }

    & > .select__indicators {
      & svg {
        color: ${getColor('main-deep-4')};
      }
    }

    & .select__value-container {
      ${getDefaultTextProps({ size: 14 })}
    }

    & > .select__indicators {
      max-height: 30px;
    }
  }

  & .select__input-container {
    padding: 0 ${getSpace('8')};
  }

  ${({ error }) =>
    error
      ? `
    & .select__control {
      border: 1px solid ${getColor('error-deep-3')};
      box-shadow: 0px 0px 5px 1px ${getColor('error-deep-3')};

      &:hover {
        border: 1px solid ${getColor('error-deep-3')};
        box-shadow: 0 0 5px 1px ${getColor('error-deep-3')};
      }

      & > .select__indicators {
        & svg {
          color: ${getColor('error-deep-3')};
        }
      }
    }
  `
      : ''}

  ${({ isDisabled }) =>
    isDisabled
      ? `
  & .select__control {
    border: 1px solid ${getColor('gray-deep-2')};
    background: ${getColor('gray-deep-1')};


    &:hover {
      border: 1px solid ${getColor('main-deep-1')};
      box-shadow: none;
    }

    & .select__placeholder span {
      color: ${getColor('gray-deep-3')};
    }

    & > .select__indicators {
      & div {
        border-top-color: ${getColor('gray-deep-3')}
      }
    }
  }
  `
      : ''}
`;

export const PlaceholderContainer = styled.div`
  padding: 0 ${getSpace('8')};
`;

export const SingleValueText = styled.span`
  padding: 0 ${getSpace('8')};
`;

export const CrossContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
`;

export const MenuContainer = styled(motion.div)`
  background: ${getColor('white-primary')};
  position: relative;
  z-index: 14;

  & > .select__menu {
    z-index: 20;
    width: 100%;
    border: 1px solid ${getColor('gray-deep-2')};
    box-shadow: 2px 2px 1px rgba(202, 205, 204, 0.25);
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    margin: 4px 0px 0px 0px;

    & > .select__menu-list > div:nth-of-type(2n) {
      background: ${getColor('main-deep-1')};
    }
  }

/
`;

export const MenuListContainer = styled.div`
  & > .select__menu-list {
    padding: 0;

    & > *:nth-of-type(2n) {
      background: ${getColor('main-deep-1')};
    }
  }
`;

export const StyledReactSelect = styled(ReactSelect)<
  SelectProps<boolean>
>`` as StateManagedSelect;

export const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 150px;
`;

export const OptionContainer = styled(GridContainer)<
  Pick<OptionProps<SelectOption, boolean>, 'isSelected'>
>`
  padding: ${getSpace('10')};

  ${({ isSelected }) =>
    isSelected
      ? `
    cursor: default;
    background: ${getColor('main-deep-4')}!important;
    &:hover {
      background: ${getColor('main-deep-4')}!important;
    }

    & svg {
        color: ${getColor('white-primary')}!important;
    }

    & span {
      color: ${getColor('white-primary')}!important;
    }
  `
      : `
    cursor: pointer;

    & span {
      color: ${getColor('main-deep-5')}!important;
    }

    &:hover {
      background: ${getColor('main-deep-3')}!important;

      & span {
        color: ${getColor('white-primary')}!important;
      }
    }
  `}
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: default;

  & > div {
    cursor: default;
  }
`;
