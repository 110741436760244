import styled from '@emotion/styled/macro';
import { getColor } from '../../../../../shared';
import {
  EmotionCheckmarkViewProps,
  EmotionStyledInputViewProps,
} from './types';

export const Container = styled.div`
  width: max-content;
`;

export const CheckmarkInputContainer = styled.div`
  height: 16px;
  width: 16px;
`;

export const Checkmark = styled.span<EmotionCheckmarkViewProps>`
  position: absolute;
  height: 16px;
  width: 16px;
  background: ${getColor('white-primary')};
  border: 1px solid ${getColor('main-deep-4')};
  box-sizing: border-box;
  box-shadow: 1px 1px 0px rgba(202, 205, 204, 0.25);
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    opacity: 0;
    box-sizing: border-box;
    left: 5px;
    top: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${getColor('white-primary')};
    transition: opacity 0.4s;
  }

  ${({ error }) =>
    error
      ? `
    border: 1px solid ${getColor('error-deep-3')};
  `
      : ''}

  ${({ disabled }) =>
    disabled
      ? `
    background: ${getColor('gray-deep-1')};
    box-shadow: none;

    &:after {
      background-color: ${getColor('gray-deep-1')};
    }
  `
      : ''}

  ${({ checked, disabled }) =>
    checked && disabled
      ? `
      &:after {
        background-color: ${getColor('gray-deep-2')};
      }
  `
      : ''}
`;

export const ContainerCheckmark = styled.div`
  position: relative;
`;

export const StyledInput = styled.input<EmotionStyledInputViewProps>`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ ${ContainerCheckmark} {
    ${Checkmark} {
      border: none;
      background: ${getColor('main-deep-4')};

      ${({ error }) =>
        error
          ? `
          background: ${getColor('error-deep-3')};
        `
          : ''}
      &:after {
        opacity: 1;
      }
    }
  }

  &:disabled ~ ${ContainerCheckmark} {
    ${Checkmark} {
      border: none;
      background: ${getColor('gray-deep-1')};
      &:after {
        display: block;
      }
    }
  }
`;
