import { useLocation } from 'react-router-dom';
import NavigationLink from '../../components/NavigationLink/NavigationLink.jsx';
import routesConfig from '../../../pages/routesConfig';
import './NavigationMenu.scss';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import ProfileButton from '../ECard/Profile/components/Buttons/NavButton/NavButton';

const NavigationMenu = () => {
  const { pathname } = useLocation();
  return (
    <nav className="nav gaps-2">
      <ProfileButton />
      {routesConfig.map((route) => {
        return (
          route.title &&
          pathname !== route.path &&
          !route?.linkedPaths?.includes(pathname) && (
            <NavigationLink
              key={route.path}
              path={route.path}
              Icon={route.Icon}
              title={route.title}
              isExternal={route.isExternal}
            />
          )
        );
      })}
      <LanguageSwitcher />
    </nav>
  );
};

export default NavigationMenu;
