export const productsMap = {
  whereIsMyChair: 'where-is-my-chair',
  patientDashboard: 'patient-dashboard',
};

export default [
  {
    name: 'nav.dropdown.productsList.whereIsMyChair',
    path: productsMap.whereIsMyChair,
  },
  // {
  //   name: 'nav.dropdown.productsList.patientDashboard',
  //   path: productsMap.patientDashboard,
  // },
];
