import styled from '@emotion/styled/macro';
import { getColor } from '../../../shared';
import { EmotionStyledInputViewProps, EmotionToggleViewProps } from './types';

export const Container = styled.div`
  height: 18px;
  width: 30px;
`;

export const Toggler = styled.span<EmotionToggleViewProps>`
  position: absolute;
  height: 18px;
  width: 30px;
  background: ${getColor('main-deep-2')};
  box-sizing: border-box;
  border-radius: 10px;

  &:after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    left: 3px;
    top: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${getColor('white-primary')};
    transition: left 0.2s ease-in-out;
    box-shadow: 0.5px 0.5px 1px rgba(113, 121, 130, 0.13);
  }

  ${({ error }) =>
    error
      ? `
    background: ${getColor('error-deep-3')}
  `
      : ''}

  ${({ disabled }) =>
    disabled
      ? `
    background: ${getColor('gray-deep-1')};
  `
      : ''}
`;

export const ContainerToggler = styled.div`
  position: relative;
`;

export const StyledInput = styled.input<EmotionStyledInputViewProps>`
  position: absolute;
  opacity: 0;
  height: 18px;
  width: 30px;
  margin: 0;

  &:checked ~ ${ContainerToggler} {
    ${Toggler} {
      background: ${getColor('main-deep-4')};

      &:after {
        left: calc(100% - 15px);
      }

      ${({ error }) =>
        error
          ? `
          background: ${getColor('error-deep-3')};
      `
          : ''}

      ${({ disabled }) =>
        disabled
          ? `
          background: ${getColor('gray-deep-2')};

          `
          : ''}
    }
  }
`;
