import main from './main';
import local from './local';

const getEnvs = () =>
    ({
        production: main,
        development: local,
    }[process.env.NODE_ENV || 'production']);

export default getEnvs;
