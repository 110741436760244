import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoSvg } from '../../../assets/images/logo.svg';
import fadeInAnimation from '../../../animations/fadeIn.js';
import './Logo.scss';

const Logo = () => {
  const { t } = useTranslation();
  return <LogoSvg style={{ opacity: 0, animation: fadeInAnimation() }} title={t('logo.title')} />;
};

export default Logo;
