import styled from '@emotion/styled'
import { FlexContainer } from '../../Containers';
import { getColor } from '../../../shared';
import { InputIconEmotionProps, ResetIconWrapperEmotionProps } from './types'
import { IconComponent } from '../../Icon';

export const IconContainer = styled(FlexContainer)`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 1;

  & > * {
    margin-right: 10px;
  }
`
export const Icon = styled(IconComponent)<InputIconEmotionProps>`
  cursor: ${({ action }) => (action ? 'pointer' : 'default')};
`

export const InputContainer = styled.div`
  width: 100%;
  display: inline-flex;
  position: relative;
`

export const ResetIconContainer = styled.div<ResetIconWrapperEmotionProps>`
  display: flex;
  svg {
    color: ${({ error }) => getColor(error ? 'error-deep-3' : 'main-deep-4')};
  }
`
