import axios from 'axios';
import { useNavigate } from 'react-router';
import { logOut } from '../../core/ECard/services/authService';
import authTokenInterceptor from './utils/authTokenInterceptor';
import routes from '../../../pages/routes';
import React from 'react';

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  authTokenInterceptor();

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log(error);
      if (
        error.response.status === 401 &&
        !error.config.url.includes('login') &&
        !error.config.url.includes('activate')
      ) {
        logOut();
        navigate(routes.loginPagePath());
      }
      throw error;
    }
  );

  return <>{children}</>;
};
export default AuthProvider;
