import { css } from '@emotion/react';
import { PREFIX } from '../constants';
import { SPACINGS } from './constants';

export const APP_SPACINGS = css(
  Object.entries(SPACINGS).reduce(
    (acc, [key, value]) => `${acc}${PREFIX.SPACING}${key}: ${value || 'none'};`,
    ''
  )
);
