import styled from '@emotion/styled';
import { getColor, getSpace } from '../../../../shared';
import { HorisontalActionInputDecoratorViewProps } from './types';

export const Container = styled.div<HorisontalActionInputDecoratorViewProps>`
  padding: ${getSpace('6')} ${getSpace('12')} ${getSpace('6')} ${getSpace('4')};
  border-radius: 4px;
  background: transparent;
  margin: 0 -4px 0;

  ${({ label, disabled }) =>
    !disabled &&
    label &&
    `
      :hover {
        background: ${getColor('main-deep-1')};
      }
      `};
  ${({ label }) =>
    !label &&
    `
    margin: 0;
    padding: 0;
        `};
`;
