import { MouseEvent, useMemo } from 'react';
import { IconViewProps } from '../types';

export function useIconController(
  disabled: IconViewProps<any>['disabled'],
  onClick: IconViewProps<any>['onClick']
) {
  const onClickHandler = useMemo(
    () =>
      !disabled && typeof onClick === 'function'
        ? (e: MouseEvent<HTMLDivElement>) => {
          if (typeof e?.stopPropagation === 'function') {
            e.stopPropagation();
          }
          onClick(e);
        }
        : undefined,
    [disabled, onClick]
  );

  return { onClickHandler };
}
