import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Image } from 'antd';
import { BiUser } from 'react-icons/bi';
import routes from '../../../../../../../pages/routes';
import authMap from '../../../../models/AuthMap';
import React from 'react';

const NavButton = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const avatar = localStorage.getItem(authMap.userAvatar);
  const jwt = localStorage.getItem(authMap.userToken);

  const goToProfile = () => navigate(jwt ? routes.eCardProfilePath() : routes.loginPagePath());

  return pathname !== routes.eCardProfilePath() && pathname !== routes.loginPagePath() ? (
    <button type="button" className="switcher-btn" onClick={goToProfile}>
      {avatar ? (
        <Image
          preview={false}
          src={avatar}
          style={{ objectFit: 'cover', borderRadius: '100%', aspectRatio: '1/1' }}
        />
      ) : (
        <BiUser className="color-white w-100 h-100" />
      )}
    </button>
  ) : null;
};
export default NavButton;
