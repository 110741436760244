import { THEME_COLORS } from '../../shared/colors/types';

export enum AVAILABLE_WEIGHT_TYPES {
  Bold = 'bold',
  Light = '300',
  Thin = '400',
  Medium = '500',
  Regular = 'normal',
  VeryBig = '700',
  Giant = '800',
}

export interface TextViewProps {
  weight?: keyof typeof AVAILABLE_WEIGHT_TYPES;
  size?: number;
  uppercase?: boolean;
  style?: React.CSSProperties;
  className?: string;
  color?: keyof THEME_COLORS;
  align?: React.CSSProperties['textAlign'];
  text?: string;
  lineHeight?: string | number;
}

export type EmotionTextProps = Omit<TextViewProps, 'text'>;
