import styled from "@emotion/styled";
import { getColor } from '../../../shared';
import { SvgContainerViewProps } from "./types";

export const Svg = styled.svg<SvgContainerViewProps>`
  width: 100%;
  height: 100%;
  ${({ color }) => (color ? `color: ${getColor(color)}` : "")};
  ${({ fill }) => (fill ? `fill: ${getColor(fill)};` : "")}
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
