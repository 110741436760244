import styled from '@emotion/styled';
import { getSpace } from '../../../../shared';
import { Text } from '../../../Text';

export const Container = styled.div``;

export const Title = styled(Text)`
  display: block;
  font-size: 1em;
  padding-top: ${getSpace('8')};
  padding-left: ${getSpace('14')};
`;
