import styled from '@emotion/styled';
import { getColor, getSpace } from '../../../../shared';
import { GridContainer } from '../../../Containers';

export const List = styled(GridContainer)`
  overflow-wrap: anywhere;
  border-bottom: 1px solid ${getColor('main-deep-2')};
  padding: ${getSpace('12')} 0px;

  &:last-of-type {
    border-bottom: none;
  }

  & > span:last-of-type {
    text-align: end;
  }
`;
