import { THEME_COLORS } from './types';

export const COLORS: THEME_COLORS = {
  'white-primary': '#ffffff',
  'black-primary': '#000000',

  'gray-deep-1': '#E9E9EA',
  'gray-deep-2': '#C3C4C9',
  'gray-deep-3': '#8A8C91',
  'gray-deep-4': '#4C4E4F',

  'main-deep-1': '#15265A', //F6F8FF
  'main-deep-2': '#1F3B8E', //DCE3F5
  'main-deep-3': '#5A79CB', //5A79CB
  'main-deep-4': '#DCE3F5', //1F3B8E
  'main-deep-5': '#F6F8FF', //15265A

  'error-deep-1': '#FFF6F6',
  'error-deep-2': '#F5DCDC',
  'error-deep-3': '#B80929',
  'error-deep-4': '#6E0518',

  'success-deep-1': '#F6FFF6',
  'success-deep-2': '#E4F6ED',
  'success-deep-3': '#62D3A4',
  'success-deep-4': '#0F7C62',
  'success-deep-5': '#084335',

  'warning-deep-1': '#FFFAEA',
  'warning-deep-2': '#FFEFC5',
  'warning-deep-3': '#EACD83',
  'warning-deep-4': '#C65C10',
};
