import styled from '@emotion/styled';
import { getDefaultTextProps } from '../../../Text/shared.styles';
import { getColor, getSpace } from '../../../../shared';
import { EmotionInputViewProps } from './types';

export const TextInput = styled.input<EmotionInputViewProps>`
  position: relative;
  display: block;
  width: inherit;
  border-radius: 4px;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-color: ${getColor('main-deep-4')};
  background-color: #030303;
  padding: ${getSpace('6')} ${getSpace('32')} ${getSpace('6')} ${getSpace('12')};
  ${getDefaultTextProps({ size: 14 })};

  &::placeholder {
    color: ${getColor('main-deep-4')};
  }

  &:hover,
  &:focus {
    border: 1px solid ${getColor('main-deep-4')};
    box-shadow: 0 0 5px 1px ${getColor('main-deep-2')};
  }

  ${({ error }) =>
    error &&
    `
    color: ${getColor('error-deep-3')};
    border-color: ${getColor('error-deep-3')};
    box-shadow: 0px 0px 5px 1px ${getColor('error-deep-3')};

    &:focus,&:hover {
      border-color: ${getColor('error-deep-3')};
      box-shadow: 0px 0px 5px 1px ${getColor('error-deep-3')};
    }

    &::placeholder {
      color: ${getColor('error-deep-3')};
    }
  `}

  &:disabled {
    background-color: ${getColor('gray-deep-1')};
    cursor: not-allowed;
    color: ${getColor('gray-deep-3')};
    border-color: ${getColor('gray-deep-2')};
    pointer-events: none;

    &::placeholder {
      color: ${getColor('gray-deep-2')};
    }
  }
`;
