import { THEME_SPACINGS } from '../../shared/spacings/types';
import { SIZES } from './types';

export const PADDINGS: {
  [key in keyof typeof SIZES]: {
    x: keyof THEME_SPACINGS;
    y: keyof THEME_SPACINGS;
  };
} = {
  XS: {
    y: '18',
    x: '20',
  },
  S: {
    y: '20',
    x: '20',
  },
  M: {
    y: '32',
    x: '32',
  },
  L: {
    y: '20',
    x: '40',
  },
  XL: {
    x: '60',
    y: '60',
  },
};
