import { useIconController } from './controller';
import { IconViewProps } from './types';
import { IconContainer } from './styles';
import React from 'react';

export function IconComponent({
  onClick,
  disabled,
  fill,
  color,
  size,
  width,
  height,
  className,
  children,
}: IconViewProps<any>) {
  const { onClickHandler } = useIconController(disabled, onClick);

  return (
    <IconContainer
      className={className}
      height={height}
      width={width}
      onClick={onClickHandler}
      color={color}
      size={size}
    >
      {children}
    </IconContainer>
  );
}
