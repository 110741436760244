import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import productsRoutes from '../Products/productsRoutes';
// @ts-ignore
import styles from './DropdownMenu.module.scss';
import './DropdownMenuMobile.scss';
import routes from '../../../pages/routes';
import React from 'react';
import { Button, Drawer } from 'antd';
// @ts-ignore
import { ReactComponent as MenuSvg } from '../../../assets/icons/menu.svg';
import clsx from "clsx";

const DropdownMenu = () => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const handleIsOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <Button
        icon={<MenuSvg />}
        type="link"
        size="large"
        className={styles.dropdownBtn}
        onClick={handleIsOpen}
      />
      <Drawer placement="left" onClose={handleIsOpen} open={isOpen}>
        <a className={styles.bmItem} onClick={handleIsOpen}>
          {t('nav.dropdown.products')}
        </a>
        <div style={{ marginLeft: '15px' }}>
          {productsRoutes.map((pr) => (
            <Link
              className={clsx(styles.bmItem, styles.borderBottom)}
              key={pr.path}
              to={[routes.productsPagePath(), pr.path].join('/')}
              onClick={handleIsOpen}
            >
              {t(pr.name)}
            </Link>
          ))}
        </div>
        {/* <Link className="menu-item" to={routes.servicesPagePath()} onClick={closeSideBar}>
              {t('nav.dropdown.services')}
            </Link> */}
        <Link className={styles.bmItem} to={routes.teamPagePath()} onClick={handleIsOpen}>
          {t('nav.dropdown.team')}
        </Link>
      </Drawer>
    </>
  );
};

export default DropdownMenu;
