import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals.js';
import init from './init';
import './index.scss';

const app = async () => {
  createRoot(document.getElementById('root')).render(await init());
};

// const root = document.getElementById('root');
// const virtualDom = await init();
// ReactDOM.render(virtualDom, root);

app();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
