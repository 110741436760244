import { Layout } from 'antd';
import NavigationMenu from '../NavigationMenu/NavigationMenu.jsx';
import fadeInAnimation from '../../../animations/fadeIn.js';
import './Footer.scss';
import DropdownMenu from '../DropdownMenu/DropdownMenuMobile';

const Footer = () => {

  return (
    <Layout.Footer
      className="page-footer z-0"
      style={{
        opacity: 0,
        animation: fadeInAnimation(),
        justifyContent: 'space-between',
      }}
    >
      <DropdownMenu />
      <div
        className="z-100"
        id="page-wrap"
        style={{
          pointerEvents: 'fill',
        }}
      >
        <NavigationMenu />
      </div>
    </Layout.Footer>
  );
};

export default Footer;
