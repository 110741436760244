import { Suspense } from 'react';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Routes, redirect } from 'react-router';
import { ToastContainer } from 'react-toastify';
import routesConfig from '../pages/routesConfig';
import Header from './core/Header/Header.jsx';
import Footer from './core/Footer/Footer.jsx';
import Loader from './components/Loader/index.jsx';
import './App.scss';
import routes from '../pages/routes';
import AuthProvider from './components/Auth/AuthProvider';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Toastify.scss';
import { ConfigProvider } from 'antd';
import React from 'react';

const RouterContainer = ({ children }) => {
  const { pathname } = useLocation();

  const classNames = cn({
    'h-100': true,
    'w-100': true,
    'router-container': true,
    'app-background': pathname === '/',
  });

  return <div className={classNames}>{children}</div>;
};

const App = () => {

  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextPlaceholder: '#C3C4C9',
          fontFamily: 'StaromoskovskyRegular, sans-serif',
          colorBgContainer: '#4C4E4F',
        },
      }}
    >
      <Router>
        <AuthProvider>
          <DndProvider backend={HTML5Backend}>
            <div className="app">
              <div className="h-100">
                <RouterContainer>
                  <Header />
                  <Routes>
                    {routesConfig.map(({ path, isPrivate, Component }) =>
                      isPrivate ? null : (
                        <Route
                          key={path}
                          path={path}
                          element={
                            <Suspense fallback={<Loader />}>
                              <Component />
                            </Suspense>
                          }
                        />
                      )
                    )}
                    <Route
                      action={() => {
                        return redirect(routes.mainPagePath());
                      }}
                      path="/*"
                    />
                  </Routes>
                  <Footer />
                </RouterContainer>
              </div>
              <ToastContainer />
            </div>
          </DndProvider>
        </AuthProvider>
      </Router>
    </ConfigProvider>
  );
};

export default App;
