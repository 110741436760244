import styled from '@emotion/styled';
import { FlexContainerEmotionProps } from './types';

export const FlexContainer = styled.div<FlexContainerEmotionProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  align-content: ${({ alignContent }) => alignContent || 'start'};
  align-self: ${({ alignSelf }) => alignSelf || 'stretch'};
  ${({ flex }) => (flex ? `flex: ${flex};` : '')}
`;
