import styled from '@emotion/styled';
import { APP_COLORS } from '../../colors';
import { APP_SPACINGS } from '../../spacings';

export const ThemeContainer = styled.div`
  ${APP_COLORS}
  ${APP_SPACINGS}
  height: 100%;
  
  //safari-button-margin
  button {
    margin: 0;
  }
`;
