import axios, { InternalAxiosRequestConfig } from 'axios';
import authMap from '../../../core/ECard/models/AuthMap';

const authTokenInterceptor = (): number =>
  axios.interceptors.request.use((config) => {
    const jwt = localStorage.getItem(authMap.userToken);
    if (jwt) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${jwt}`,
        },
      } as InternalAxiosRequestConfig;
    }
    return config;
  });

export default authTokenInterceptor;
