import { THEME_SPACINGS } from './types';

export const SPACINGS: THEME_SPACINGS = {
  '2': '2px',
  '4': '4px',
  '6': '6px',
  '8': '8px',
  '10': '10px',
  '12': '12px',
  '14': '14px',
  '18': '18px',
  '20': '20px',
  '24': '24px',
  '28': '28px',
  '32': '32px',
  '40': '40px',
  '60': '60px',
  '70': '70px',
};
