import styled from '@emotion/styled/macro';
import { getColor } from '../../../shared';
import { EmotionCheckmarkViewProps, EmotionSizeProps } from './types';

export const Container = styled.div<EmotionSizeProps>`
  ${({ size }) => `
    height: ${size}px;
    width: ${size}px;
  `}
`;

export const Checkmark = styled.span<EmotionCheckmarkViewProps>`
  position: absolute;
  background: ${getColor('white-primary')};
  border: 1px solid ${getColor('main-deep-4')};
  box-sizing: border-box;
  box-shadow: 1px 1px 0px rgba(202, 205, 204, 0.25);
  border-radius: 4px;
  ${({ size }) => `
    height: ${size}px;
    width: ${size}px;
  `}

  ${({ disabled }) => (disabled ? 'opacity: 0.6;' : '')}

  &:after {
    content: '';
    position: absolute;
    opacity: 0;
    box-sizing: border-box;
    ${({ size }) => {
      const height = size / 2;
      const width = size / 4;
      return `
      height: ${height}px;
      width: ${width}px;
      left: ${(size - width) / 2}px;
      top: ${(size - height) / 3}px;
    `;
    }}
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: ${getColor('white-primary')};
    transform: rotate(45deg);
    transition: opacity 0.4s;
  }

  &:before {
    content: '';
    position: absolute;
    display: none;
    box-sizing: border-box;
    ${({ size }) => {
      const height = size / 2;
      const width = size / 2;
      return `
      width: ${width}px;
      left: ${(size - width) / 2}px;
      top: ${height - 1}px;
    `;
    }}
    height: 1px;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: ${getColor('white-primary')};
  }
  ${({ error }) =>
    error
      ? `
    &:after {
      border-color: ${getColor('error-deep-4')};
    }

    &:before {
      border-color: ${getColor('error-deep-4')};
    }
  `
      : ''}

  ${({ disabled }) =>
    disabled
      ? `
    border-color: ${getColor('gray-deep-1')};
    box-shadow: none;
    background: ${getColor('gray-deep-1')};

    &:after {
      border-color: ${getColor('gray-deep-2')};
    }

    &:before {
      border-color: ${getColor('gray-deep-2')};
    }
  `
      : ''}
`;

export const ContainerCheckmark = styled.div`
  position: relative;
`;

export const StyledInput = styled.input<EmotionSizeProps>`
  position: absolute;
  opacity: 0;
  margin: 0;
  ${({ size }) => `
    height: ${size}px;
    width: ${size}px;
  `}

  &:checked ~ ${ContainerCheckmark} {
    ${Checkmark} {
      border: none;
      background: ${getColor('main-deep-4')};
      &:after {
        opacity: 1;
      }
    }
  }

  &:indeterminate ~ ${ContainerCheckmark} {
    ${Checkmark} {
      border: none;
      background: ${getColor('main-deep-4')};
      &:before {
        display: block;
      }
    }
  }

  ${({ disabled }) =>
    disabled
      ? `
  &:checked ~ ${ContainerCheckmark} {
    ${Checkmark} {
      background: ${getColor('gray-deep-1')};
    }
  }

  &:indeterminate ~ ${ContainerCheckmark} {
    ${Checkmark} {
      background: ${getColor('gray-deep-1')};
    }
  }
  `
      : ''}
`;
