import { Link } from 'react-router-dom';
import './NavigationLink.scss';

const NavigationLink = ({ path, Icon, isExternal }) => {
  // eslint-disable-next-line react/no-unstable-nested-components
  const LinkContent = () => (
    <div className="nav-btn">
      <Icon style={{ width: '90%', height: '90%', color: 'white' }} />
    </div>
  );

  return isExternal ? (
    <a href={path} target="_blank" rel="noreferrer" className="nav-link">
      <LinkContent />
    </a>
  ) : (
    <Link to={path} key={path} className="nav-link">
      <LinkContent />
    </Link>
  );
};

export default NavigationLink;
