import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FlagRu from '../../../assets/icons/flag-ru.svg';
import FlagEn from '../../../assets/icons/flag-en.svg';
import { ReactComponent as LanguageSvg } from '../../../assets/icons/language.svg';
import './LanguageSwitcher.scss';

const languagesMap = {
  ru: 'ru',
  en: 'en',
};

const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  const availableLanguages = [
    { value: languagesMap.ru, view: t('languages.ru'), Icon: FlagRu },
    { value: languagesMap.en, view: t('languages.en'), Icon: FlagEn },
  ];
  const findLanguageByValue = (value) => availableLanguages.find((l) => l.value === value);
  const [currentLanguage, setCurrentLanguage] = useState(findLanguageByValue(i18n.language));

  const changeLanguage = (value) => () => {
    setCurrentLanguage(findLanguageByValue(value));
    return i18n.changeLanguage(value);
  };

  return (
    <button
      type="button"
      className="switcher-btn"
      onClick={changeLanguage(
        currentLanguage.value === languagesMap.en ? languagesMap.ru : languagesMap.en
      )}
    >
      <LanguageSvg />
    </button>
  );
};

export default LanguageSwitcher;
