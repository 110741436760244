import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconContainerEmotionProps } from './types';

export const IconContainer = styled.div<IconContainerEmotionProps>`
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  cursor: ${({ onClick }) => (typeof onClick === 'function' ? 'pointer' : '')};
  ${({ size, width = 16, height = 16 }) =>
    css`
      width: ${size || width}px;
      height: ${size || height}px;
    `}
`;
