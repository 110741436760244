import { getColor } from '../../shared';
import { AVAILABLE_WEIGHT_TYPES, EmotionTextProps } from './types'

export function getDefaultTextProps({
  weight,
  size = 14,
  lineHeight,
  uppercase,
  color,
  align,
}: EmotionTextProps) {
  return `
      font-style: normal;
      font-weight: ${AVAILABLE_WEIGHT_TYPES[weight || 'Regular']};
      font-size: ${size}px;
      line-height: ${lineHeight || '1.3'};
      color: ${getColor(color || 'main-deep-5')};
      ${align ? `text-align: ${align};` : ''}
      ${uppercase ? 'text-transform: uppercase;' : ''}
    `
}
