import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import Logo from '../../components/Logo/Logo.jsx';
import routes from '../../../pages/routes';
import './Header.scss';

const { Header: PageHeader } = Layout;

const Header = () => (
  <PageHeader className="page-header">
    <Link to={routes.mainPagePath()} className="logo-link">
      <Logo />
    </Link>
  </PageHeader>
);

export default Header;
